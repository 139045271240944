import { Col, Row } from "antd";
import styled from "styled-components";

const ContentProducts = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-items: end;
  justify-content: center;
  margin-bottom: 30px;
  font-family: "Baloo Tamma 2", system-ui;
  font-weight: 550;
  font-size: 1.1rem;
  padding: 5px;
  margin-left: 0px !important;
  button {
    margin: 20px auto;
    align-items: center;
    display: flex;
    width: 100px;
    min-height: 30px;
    font-size: 10px;
    height: 40px;
    color: black;
    background-color: white;
  }
  .btnFilter {
    border: none;
    background-color: transparent;
    text-align: center;
    justify-content: center;
  }
`;

const ContentImgProduct = styled(Row)`
  border-radius: 7px;
  border-color: transparent;
  border: 5px;
  background-color: transparent;

  display: flex;
  flex-direction: column;
  border-radius: 7px;
  cursor: pointer;

  img {
    border: 5px;
    border-radius: 7px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    transition: transform 5s ease-in-out;

    /* min-height: ${(props) => (props.responsive ? "210px" : "340px")};
    max-height: ${(props) => (props.responsive ? "210px" : "340px")};
    min-width: ${(props) => (props.responsive ? "210px" : "14rem")}; */
    border: 5px;
    border-radius: 7px;

    transition: transform 0.4s ease;
  }

  :hover img {
    transform: scale(1.03);
  }
`;

// const ImageContainer = styled(Row)`
//   width: 100%;
//   min-height: ${(props) => (props.responsive ? "250px" : "380px")};
//   overflow: hidden;
// `;

const ImageContainer = styled(Row)`
  position: relative;
  width: 100%;
  min-height: ${(props) => (props.responsive ? "250px" : "380px")};
  overflow: hidden;
`;

const RowNew = styled(Row)`
  background-color: black;
  position: absolute;
  color: white;
  padding: 5px 10px;
  /* top: 10px; */
  border-radius: 10px;
  border-top-left-radius: 0px;
  max-height: 40px !important;
`;
const NewLabel = styled.span`
  color: white;
  font-weight: bold;
  font-size: 2vh !important;
`;

const RowOff = styled(Row)`
  background-color: #a6a69f;
  position: absolute;
  color: white;
  padding: 5px;
  /* top: 10px; */
  border-radius: 10px;
  border-top-right-radius: 0px;
  z-index: 10;
  max-height: 40px !important;
`;

const LabelOff = styled.span`
  color: white;
  font-weight: bold;
  font-size: 2vh !important;
`;

const CardProduct = styled(Row)`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  img {
    /* width: 100%;
    height: 100%;
    border-radius: 7px;
    min-height: ${(props) => (props.responsive ? "510px" : "100%")};
    max-height: ${(props) => (props.responsive ? "510px" : "100%")}; */
    width: 100%;
    height: 100%;
    object-fit: cover; /* Asegura que la imagen cubra todo el espacio disponible sin distorsionarse */
    border-radius: 7px;
    min-height: ${(props) => (props.responsive ? "250px" : "380px")};
    max-height: 380px;
  }

  border: 2px solid #f1f1f1;
  border-radius: 10px;
  cursor: pointer;
  :hover {
    -webkit-box-shadow: 1px 10px 14px -5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 10px 14px -5px rgba(0, 0, 0, 0.75);
    box-shadow: 1px 10px 14px -5px rgba(0, 0, 0, 0.75);
  }

  span {
    text-align: center;

    font-family: "Baloo Tamma 2", system-ui;
    font-weight: 550;
    font-size: 1.1rem;
  }
`;

const TitleContentProduct = styled.span`
  margin-top: 50px;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
`;

const TitlePanelFilters = styled.span`
  margin-top: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
margin-left: 10px;
`;

const ContenCustom = styled(Row)`
  /* background: #E3E1D9; */
  background: white;
  /* margin-top: 60px; */
  /* min-width: 630px; */
  .title {
    background-color: aliceblue;
  }
`;

const ItemName = styled.span`
  font-size: 1rem !important;
  margin-top: 10px;
`;

const ItemPrice = styled.span`
  color: ${(props) => (props.PriceOff ? "red" : "#919191")};
  font-size: 0.9rem !important;
  text-decoration: ${(props) => (props.PriceOff ? "line-through" : "none")};
`;

const ItemPriceOff = styled.span`
  color: black;
  font-size: 0.9rem !important;
  color: #919191;
`;

const VeronaText = styled.span`
  margin-top: 10px;
  font-size: 1.1rem !important;
  color: #919191;
`;

const ButtonsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.9s ease;

  button {
    display: flex;
    margin-top: 0px;
    justify-content: center;
    align-items: center;
    max-width: 45px;
    border-radius: 7px;
    box-shadow: none;
    background: transparent;
    border: none;
  }
  ${ContentImgProduct}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

export {
  CardProduct,
  ContentProducts,
  ContentImgProduct,
  ItemName,
  ItemPrice,
  TitleContentProduct,
  ContenCustom,
  ButtonsContainer,
  VeronaText,
  ImageContainer,
  ItemPriceOff,
  NewLabel,
  RowNew,
  TitlePanelFilters,
  RowOff,
  LabelOff,
};
