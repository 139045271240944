import { useCallback, useEffect, useState } from "react";
import codesServices from "../../../services/codes/codesServices";

const useCodes = () => {
  const {
    getAllCodes,
    getCode,
    updateCode,
    createCode,
    DeleteCode,
    validateCode,
  } = codesServices();
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState({});
  const [isValidateCode, setIsValidateCode] = useState({});
  const [dataCodes, setDataCodes] = useState([]);

  const getCodes = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getAllCodes();
      setDataCodes(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const onCreateCode = useCallback(async (data) => {
    try {
      await createCode(data);
      getCodes();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getDataCode = useCallback(
    async (idCode) => {
      try {
        const dataCode = await getCode(idCode);
        console.log(dataCode);
        if (dataCode) {
          setCode(dataCode);
        } else {
          setCode({});
        }
      } catch (error) {
        console.error(error);
        setCode({});
      }
    },
    [getCode]
  );

  const codeUpdate = useCallback(async (idCode, dataCode) => {
    try {
      await updateCode(idCode, dataCode);
      getCodes();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const onDeleteCode = async (data) => {
    try {
      await DeleteCode(data._id);
      getCodes();
    } catch (error) {
      console.error(error);
    }
  };

  const onvalidateCode = async (data) => {
    try {
      const dataValidate = await validateCode(data);
      console.log(dataValidate);
      setIsValidateCode(dataValidate);
      getCodes();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCodes();
  }, [getCodes]);

  return {
    isLoading,
    dataCodes,
    getDataCode,
    code,
    codeUpdate,
    onCreateCode,
    setCode,
    onDeleteCode,
    onvalidateCode,
    isValidateCode,
  };
};

export default useCodes;
