// Footer.js
import React from "react";
import { ContentIcon, ContentInfo, FooterContainer } from "./style";

import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import {
  viewsFooterAbout,
  viewsFooterConstants,
} from "../../constants/viewsFooterConstants";
import FormSubscribe from "../formSubscribe/FormSubscribe";

const FooterCustom = () => {
  return (
    <>
      <Col xs={24} md={24}>
        <FooterContainer>
          <Col xs={24} md={8}>
            <FormSubscribe />
          </Col>



          <Col xs={24} md={16}>
            <ContentInfo>
              <Col xs={8} md={4}>
                <Row>
                  <Col md={24} xs={24}>
                    <span>Servicios</span>
                    {viewsFooterConstants.map((view) => (
                      <div key={view._id} className="ContentLinkOptions">
                        <Link className="link" to={`/InfoVerona/${view.id}`}>
                          {view.label}
                        </Link>
                      </div>
                    ))}
                  </Col>
                </Row>
              </Col>

              <Col xs={8} md={4}>
                <Row>
                  <Col md={24} xs={24}>
                    <span>Nosotros</span>
                    {viewsFooterAbout.map((view) => (
                      <div key={view._id} className="ContentLinkOptions">
                        <Link className="link" to={`/InfoVerona/${view.id}`}>
                          {view.label}
                        </Link>
                      </div>
                    ))}
                  </Col>
                </Row>
              </Col>

              <Col xs={8} md={4}>
                <Row>
                  <Col md={24} xs={24}>
                    <span>Frecuentes</span>
                    {viewsFooterAbout.map((view) => (
                      <div key={view._id} className="ContentLinkOptions">
                        <Link className="link" to={`/InfoVerona/${view.id}`}>
                          {view.label}
                        </Link>
                      </div>
                    ))}
                  </Col>
                </Row>
              </Col>

              {/* <Col xs={6} md={6}>
                <span>Services</span>
              </Col>
              <Col xs={6} md={6}>
                <span>About</span>
              </Col>
              <Col xs={6} md={6}>
                <span>Terms</span>
              </Col> */}
            </ContentInfo>
          </Col>

          
        </FooterContainer>
      </Col>
    </>
  );
};

export default FooterCustom;
