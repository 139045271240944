import axios from "axios";
import baseUrl from "../../utils/apis/BaseUrl";
import API from "../../utils/apis";

const orderServices = () => {
  const createOrder = async (data) => {
    try {
      const orderResponse = await axios.post(
        `${baseUrl}${API.orders.root}`,
        data
      );
      return orderResponse.data;
    } catch (error) {
      console.error("Error creating order:", error);
      throw error;
    }
  };

  const updateOrder = async (id, data) => {
    console.log(data);
    try {
      const orderResponse = await axios.put(
        `${baseUrl}${API.orders.root}${id}`,
        data
      );
      console.log(orderResponse);
      return orderResponse.data;
    } catch (error) {
      console.error("Error creating order:", error);
      throw error;
    }
  };

  const getAllOrders = async () => {
    try {
      const response = await axios.get(`${baseUrl}${API.orders.root}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching all orders:", error);
      throw error;
    }
  };

  const getOrder = async (idOrder) => {
    try {
      const response = await axios.get(
        `${baseUrl}${API.orders.root}/${idOrder}`
      );
      return response.data;
    } catch (error) {
      console.error(`Error fetching order with ID ${idOrder}:`, error);
      throw error;
    }
  };

  return {
    createOrder,
    getAllOrders,
    getOrder,
    updateOrder,
  };
};

export default orderServices;
