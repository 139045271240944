import OrderPage from "../components/orders/page/OrderPage";

export const viewsFooterConstants = [
  {
    component: OrderPage,
    label: "Información de Orden",
    id: "order",
  },
  {
    component: OrderPage,
    label: "Nuestros productos",
    id: "prod",
  },
  {
    component: OrderPage,
    label: "Nuestros productos",
    id: "prod",
  },
  //   {
  //     component: ProductInfo,
  //     label: "Información de Producto",
  //     id: "product",
  //   },
];

export const viewsFooterAbout = [
  {
    // component: OrderPage,
    label: "Verona",
    id: "order",
  },
  {
    // component: OrderPage,
    label: "Verona",
    id: "order",
  }, {
    // component: OrderPage,
    label: "Verona",
    id: "order",
  }, {
    // component: OrderPage,
    label: "Verona",
    id: "order",
  },
  //   {
  //     component: ProductInfo,
  //     label: "Información de Producto",
  //     id: "product",
  //   },
];
