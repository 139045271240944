import React, { createContext, useMemo } from "react";
import PropTypes from "prop-types";
import useProducts from "../hooks/useProducts";
import useProductsActions from "../components/productsActions/hook/useProductsActions";
import useCart from "../../../cart/hooks/useCart";
import useDrawerCart from "../../drawer/hooks/useDrawerCart";
import useSearchCustom from "../../searchCustom/hooks/useSearchCustom";
import useCategorys from "../components/categories/hooks/useCategorys";
import useSelectGarmentType from "../components/selects/selectGarmentType/hooks/useSelectGarmentType";
import useReference from "../components/selects/selectGarmentType/hooks/useReference";
import useCounter from "../components/productDetails/components/hooks/useCounter";
import useOrders from "../../orders/hooks/useOrders";
import useCodes from "../../codes/hooks/useCodes";

export const ProductsContext = createContext();

const useContextValue = () => {
  const productsCrud = useProducts();
  const CodesCrud = useCodes();
  const referencesCrud = useReference();
  const drawerShoppingCart = useDrawerCart();
  const shoppingCart = useCart();
  const searchCustom = useSearchCustom({ productsCrud });
  const categorysCrud = useCategorys();
  const garmentTypeCrud = useSelectGarmentType();
  const counterCart = useCounter();
  const ordersCrud = useOrders();
  const productsActions = useProductsActions({
    removeProduct: productsCrud.removeProduct,
  });

  const contextValue = useMemo(() => {
    return {
      productsCrud,
      productsActions,
      searchCustom,
      drawerShoppingCart,
      shoppingCart,
      categorysCrud,
      garmentTypeCrud,
      referencesCrud,
      counterCart,
      ordersCrud,
      CodesCrud,
    };
  }, [
    productsCrud,
    productsActions,
    drawerShoppingCart,
    shoppingCart,
    searchCustom,
    categorysCrud,
    garmentTypeCrud,
    referencesCrud,
    counterCart,
    ordersCrud,
    CodesCrud,
  ]);

  return contextValue;
};

export const ProductsProvider = ({ children }) => {
  const contextValue = useContextValue();

  return (
    <ProductsContext.Provider value={contextValue}>
      {children}
    </ProductsContext.Provider>
  );
};

ProductsProvider.propTypes = {
  children: PropTypes.node,
};
