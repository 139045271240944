import { Col, Row } from "antd";
import styled from "styled-components";

const ContentShoppingCart = styled.div`
  display: flex;
  flex-direction: column;
  background: #f8f9fa;
  border-radius: none;

  font-family: "Baloo Tamma 2", system-ui;

  & > * {
    margin: 0rem 0;
  }

  img {
    width: 100px;
    height: 100px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
`;

const ContentDescriptionCart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ContentTotal = styled(Row)`
  margin-top: 10px;
  font-weight: bold;
  margin: 10px;
  padding-right: 10px;
  padding-top: 10px;
  div {
    padding-right: 10px;
  }

  span {
    font-size: 1rem !important;
    font-family: "Baloo Tamma 2", system-ui;
  }
`;

const ContentSummary = styled(Row)``;

const Scrollbar = styled.div`
  height: 64vh;
  margin: 0px;
  overflow-x: auto;
  padding: 0px;
  width: 100%;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    background: #f1f1f1;

    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ContentProductsCart = styled(Row)`
  gap: 15px;
`;

const ContentItemCart = styled(Row)`
  border: 2px solid #f1f1f1;
  margin: 1rem;
  padding: 1rem;
  border-radius: 20px;
  background: #f8fafa;
`;

const ContentSelectedSizes = styled(Row)`
  font-family: "Baloo Tamma 2", system-ui;
  justify-content: center;

  margin-bottom: 10px;
  span {
    font-family: "Baloo Tamma 2", system-ui;
  }
`;

const ContenNamePiece = styled(Col)`
  color: #919191;
`;

const ContensizePiece = styled(Col)`
  background-color: #dee2e6;
  max-width: 2rem;
  min-height: 1.5rem;
  text-align: center;
  border-radius: 5px;
  color: #919191;
  span {
    font-size: 0.7rem;
  }
`;

const ContentNameProduct = styled(Row)`
  color: #919191;
  text-align: center;
  margin-bottom: 20px;
  span {
    font-family: "Baloo Tamma 2", system-ui;
  }
`;

export {
  ContentShoppingCart,
  ContentDescriptionCart,
  ContentSelectedSizes,
  ContenNamePiece,
  ContensizePiece,
  ContentNameProduct,
  Scrollbar,
  ContentProductsCart,
  ContentItemCart,
  ContentTotal,
  ContentSummary,
};
