import { Col, Row } from "antd";
import React from "react";
import { Package } from "react-feather";
import { ContentViewOrder } from "../style";
import {
  ButtonStyled,
  CardIconOrder,
  CardOrder,
  PaymentStatus,
  ShippingStatus,
  TitleContentProduct,
} from "../../page/style";
import {
  PAYMENT_STATUS,
  SHIPPING_STATUS,
} from "../../../../constants/orderContants";

const OrderDetails = ({ order, dataSearchOrder }) => {
  const paymentStatus =
    order.paymentStatus === 2
      ? PAYMENT_STATUS.PAYMENT_CONFIRMED
      : PAYMENT_STATUS.PENDING_PAYMENT;
  const shippingStatus =
    order.sendStatus === 1
      ? SHIPPING_STATUS.SENT
      : SHIPPING_STATUS.PENDING_SEND;

  return (
    <>
      {order._id ? (
        <Row justify={"center"}>
          <Col xs={24} md={24}>
            <ContentViewOrder>
              <TitleContentProduct>Detalles de tu Orden</TitleContentProduct>
              <Col md={24} xs={24}>
                <Row>
                  <CardOrder>
                    <Col md={24} xs={24}>
                      {" "}
                      <p>
                        <span>Nombre:</span>{" "}
                        {order.name.slice(0,3)  || "No hay datos de la orden"}**
                      </p>
                    </Col>
                    {/* <Col md={24} xs={24}>
                      {" "}
                      <p>
                        <span>Descripción:</span>{" "}
                        {order.description || "No hay descripción"}
                      </p>
                    </Col> */}
                    {/* <Col md={24} xs={24}>
                      {" "}
                      <p>
                        <span>Email:</span> {order.email || "No hay email"}
                      </p>
                    </Col> */}
                    <Col md={24} xs={24}>
                      {" "}
                      <p>
                        <span>Celular:</span>{" "}
                        {/* {order.celphone.substring(0, 3)  || ""}** */}
                       ** {typeof order?.celphone === 'string' ? order.celphone.substring(2, 5) : "No hay celular"}

                      </p>
                    </Col>
                    <Col md={24} xs={24}>
                      {" "}
                      <p>
                        <span>Ciudad:</span> {order.city || "No hay ciudad"}
                      </p>
                    </Col>
                    {/* <Col md={24} xs={24}>
                      {" "}
                      <p>
                        <span>Departamento:</span>{" "}
                        {order.department || "No hay departamento"}
                      </p>
                    </Col> */}
                    <Col xs={24} md={24}>
                      <PaymentStatus color={paymentStatus.color}>
                        <span>Estado de Pago:</span> {paymentStatus.label}
                      </PaymentStatus>
                    </Col>
                    <Col xs={24} md={24}>
                      <ShippingStatus color={shippingStatus.color}>
                        <span>Estado de Envío:</span> {shippingStatus.label}
                      </ShippingStatus>
                    </Col>
                    <Col md={24} xs={24}>
                      {" "}
                      <p>
                        <span>Precio Total:</span> $
                        {order?.items?.reduce(
                          (total, item) => total + item.totalPrice,
                          0
                        ) || 0}
                      </p>
                    </Col>
                  </CardOrder>
                </Row>
              </Col>
            </ContentViewOrder>
          </Col>
        </Row>
      ) : (
        <>
          <Col md={24} xs={24}>
            <Row style={{ minHeight: "500px" }}>
              <ContentViewOrder>
                <CardIconOrder>
                  <Package size={80} color="#f0d8d8" />
                  {dataSearchOrder?.length > 5 ? (
                    <TitleContentProduct>
                      Verifica el numero de tu orden orden
                    </TitleContentProduct>
                  ) : null}
                </CardIconOrder>
              </ContentViewOrder>
            </Row>
          </Col>
        </>
      )}
    </>
  );
};

export default OrderDetails;
