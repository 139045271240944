import { lazy } from "react";

const ProductDetailsPage = lazy(() =>
  import("../components/products/pages/ProductDetailsPage")
);
const ProductsFiltered = lazy(() =>
  import("../components/products/pages/ProductsFilteredPage")
);
const ProductsOulet = lazy(() =>
  import("../components/products/pages/ProductsOuletPage")
);
const ProductsNews = lazy(() =>
  import("../components/products/pages/ProductsNewPage")
);
const Panel = lazy(() =>
  import(
    "../components/products/pages/PanelConfiguration/PanelConfigurationPage"
  )
);
const Verona = lazy(() =>
  import("../components/products/pages/VeronaInitialPage")
);
const Cart = lazy(() =>
  import("../cart/shoppingCartPage/pages/shoppingCartPage")
);
const DataBuy = lazy(() =>
  import("../cart/shoppingCartPage/pages/DataBuyPage")
);
const InfoVeronaPage = lazy(() =>
  import("../components/products/pages/InfoVeronaPage")
);

export const RoutesApp = [
  {
    path: "/*",
    name: "verona",
    icon: "bar-chart-2",
    element: Verona,
    protected: false,
  },
  {
    path: "/products/",
    element: ProductsFiltered,
    protected: false,
  },
  {
    path: "/oulet/",
    element: ProductsOulet,
    protected: false,
  },
  {
    path: "/news/",
    element: ProductsNews,
    protected: false,
  },
  {
    path: "/cart/",
    element: Cart,
    protected: false,
  },
  {
    path: "/dataBuy/",
    element: DataBuy,
    protected: false,
  },
  {
    path: "/product/:id?",
    name: "productsDetailsPage",
    element: ProductDetailsPage,
    protected: false,
  },
  {
    path: "/panel-configuration/:tab?",
    name: "panel",
    icon: "bar-chart-2",
    element: Panel,
    protected: true,
    allowedRoles: ["admin"],
  },
  {
    path: "/InfoVerona/:view?",
    name: "panel",
    icon: "bar-chart-2",
    element: InfoVeronaPage,
    protected: false,
  },
];
