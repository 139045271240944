import { useCallback, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const useCart = () => {
  const [cartItems, setCartItems] = useState([]);

  const onAddToCart = useCallback(
    (item) => {
      const matchItem = cartItems.find(
        (cartItem) =>
          cartItem._id === item._id &&
          cartItem.selectedPiece?.every(
            (piece, index) =>
              piece.size._id === item.selectedPiece[index]?.size?._id
          )
      );

      if (matchItem) {
        setCartItems((prevItems) =>
          prevItems.map((cartItem) =>
            cartItem.idItemCart === matchItem.idItemCart
              ? { ...cartItem, count: cartItem.count + 1 }
              : cartItem
          )
        );
      } else {
        const newItem = {
          ...item,
          idItemCart: uuidv4(),
          priceTotalItem: item.price,
          count: 1,
        };
        setCartItems((currentCart) => [...currentCart, newItem]);
      }
    },
    [cartItems]
  );

  const onChangeCountItem = ({ data, value, add }) => {
    const { idItemCart } = data;

    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.idItemCart === idItemCart
          ? {
              ...item,
              count: add ? item.count + value : Math.max(1, item.count - value),
            }
          : item
      )
    );
  };

  const onDeleteToCart = (idItemCart) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => item.idItemCart !== idItemCart)
    );
  };

  const updatePriceItem = ({ idItemCart, price, count }) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.idItemCart === idItemCart
          ? { ...item, priceTotalItem: price * count, count }
          : item
      )
    );
  };

  console.log(cartItems);

  const totalPrice = useMemo(
    () =>
      cartItems.reduce(
        (accumulator, item) =>
          accumulator + (item.priceTotalItem * item.count || item.price),
        0
      ),
    [cartItems]
  );

  return {
    onAddToCart,
    cartItems,
    setCartItems,
    updatePriceItem,
    totalPrice,
    onChangeCountItem,
    onDeleteToCart,
  };
};

export default useCart;
