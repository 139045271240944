import { Button, Col, Menu, Row } from "antd";
import styled from "styled-components";

// font-family: 'Poppins', sans-serif;
const ContentNav = styled.nav`
  position: ${(props) => (props.styleNav ? "fixed" : "")};
  /* top: 0px; */
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  gap: 20px;
  z-index: 1000;
  transition: background-color 0.3s ease;
  /* min-width: 768px; */
  background-color: ${(props) =>
    props.styleNav ? "rgba(128, 128, 128, 0.2)" : "white"};
  color: ${(props) => (props.styleNav ? "white" : "black")};

  &:hover {
    background-color: ${(props) =>
      props.styleNav ? "rgba(128, 128, 128, 0.4)" : "transparent"};
  }
  div {
    display: flex;

    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* min-width: 768px; */
  }
`;

const InputSeacrh = styled.input`
  width: 100%;
  height: 40px;
  display: flex;
  border-radius: 10px;
`;

const ContentSeacrh = styled.div`
  display: flex;
  justify-content: center;
  div {
    display: flex;
  }
`;

const ContentInitialNav = styled.div`
  display: flex;
`;

const ContentIconsUser = styled.div`
  display: flex;
  div {
    margin: auto;
  }
`;

const StyledNav = styled(Row)`
  position: fixed;
  width: 100%;
  z-index: 1000;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: background-color 0.5s ease;
  justify-self: center;

  div {
    margin: auto;
  }

  ${(props) =>
    props.visibleNav && !props.isAtTop
      ? `
 
      background-color: white;
      border-bottom: 1px solid #f0f0f0;

      box-shadow: rgbargba(255, 255, 255, 0.3) 0px 30px 60px -12px inset,
        transparent 0px 18px 36px -18px inset;
    `
      : !props.visibleNav && props.isAtTop
      ? `
      background-color: transparent;

      opacity: 1;
      box-shadow: none;
    `
      : props.showContenSearch
      ? `
      background-color: white;

      
    `
      : ``}
`;

const StyledLogo = styled(Row)`
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    max-width: 10rem;
  }
`;

const MenuItems = styled(Row)`
  cursor: pointer;
  div {
    text-align: center;
    :hover {
      /* background-color: #f0f0f0 !important;
      border-radius: 5px; */
      border-bottom: 1px gray solid;
    }
  }
  .link {
    text-decoration: none;
    color: ${(props) => (props.styleNav ? "white" : "black")};
    cursor: pointer;
    font-size: 20px;
    text-align: center;

    :hover {
      color: gray !important;
      border-radius: 5px;
    }

    /* font-family: "Montserrat", sans-serif; */

    font-family: "Baloo Tamma 2", system-ui;
    /* font-family: 'AktivGrotesk Bold', system-ui; */
  }
  .linkOff {
    text-decoration: none;
    /* font-family: "Alata", sans-serif; */
    font-family: "Baloo Tamma 2", system-ui;
    font-weight: 500;
    font-size: 20px;
    text-align: center;

    cursor: pointer;
    color: red;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  font-family: "Montserrat", sans-serif;
`;

const MobileNavButton = styled(Button)`
  display: flex;
  margin: auto;
  border: none;
  justify-content: left;
  margin-left: 0px;
  background-color: transparent;
  box-shadow: none;
  align-items: center;
  justify-content: center;
  @media (min-width: 769px) {
    display: none;
  }
`;

const IconsContainer = styled(Row)`
  justify-content: center;
  vertical-align: center;
  align-self: center !important   ;
  div {
    display: flex;
    justify-content: center;
    vertical-align: center;
    :hover {
      color: #75777e;
      opacity: 0.6;
    }
  }
  button {
    border: none;
    box-shadow: none;
    background: transparent;
    width: 100%;
  }
`;

const ContentSubscribe = styled.div`
  justify-content: center;
  height: 100%;
  bottom: 0px !important;
`;

const ContentMobileNav = styled(Row)`
  margin-top: 5px;
`;

const WarningNavContent = styled.div`
  width: 100%;
  background-color: black;
  height: 40px;
  text-align: center;
  /* min-width: 768px; */
`;

const MessageWarnign = styled.span`
  color: white;
`;

export {
  ContentNav,
  InputSeacrh,
  ContentSeacrh,
  ContentInitialNav,
  ContentIconsUser,
  StyledLogo,
  StyledMenuItem,
  MobileNavButton,
  IconsContainer,
  StyledNav,
  ContentMobileNav,
  MenuItems,
  WarningNavContent,
  MessageWarnign,
  ContentSubscribe,
};
