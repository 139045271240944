import { Button, Card, Row } from "antd";
import styled from "styled-components";

const ContentViewOrder = styled.div`
  padding: 40px;
  background: linear-gradient(135deg, #e0eafc, #cfdef3);
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  margin: auto;
`;

const TitleContentProduct = styled.h2`
  text-align: center;
  color: #333;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 20px;
  font-size: 2em;
`;

const CardOrder = styled(Card)`
  margin-top: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  p {
    margin: 10px 0;
    font-family: "Montserrat", sans-serif;

    font-size: 16px;
  }
`;

const CardIconOrder = styled(Card)`
  margin-top: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  align-content: center;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    margin: 10px 0;
    font-family: "Montserrat", sans-serif;

    font-size: 16px;
  }
`;

const StatusContainer = styled(Row)`
  padding: 10px 15px;
  border-radius: 8px;
  margin: 5px 0;
  opacity: 0.9;
  border: 1px solid #ccc;
  width: 100%;
  text-align: center;
`;

const PaymentStatus = styled(StatusContainer)`
  background-color: ${(props) => props.color};
`;

const ShippingStatus = styled(StatusContainer)`
  background-color: ${(props) => props.color};
`;

const ButtonStyled = styled(Button)`
  margin-top: 20px;
  width: 100%;
  background-color: #007bff;
  color: white;
  border: none;

  &:hover {
    background-color: #0056b3;
  }
`;

export {
  ContentViewOrder,
  TitleContentProduct,
  CardOrder,
  StatusContainer,
  PaymentStatus,
  ShippingStatus,
  ButtonStyled,
  CardIconOrder,
};
