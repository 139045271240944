import { useCallback, useEffect, useState } from "react";
import orderServices from "../../../services/orders/orderServices";

const useOrders = () => {
  const { getAllOrders, getOrder, updateOrder } = orderServices();
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState({});
  const [dataOrders, setDataOrders] = useState([]);

  const getOrders = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getAllOrders();
      setDataOrders(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getDataOrder = useCallback(
    async (idOrder) => {
      try {
        const dataOrder = await getOrder(idOrder);
        console.log(dataOrder)
        if (dataOrder) {
          setOrder(dataOrder);
        } else {
          setOrder({});
        }
      } catch (error) {
        console.error(error);
        setOrder({});
      }
    },
    [getOrder]
  );

  const orderUpdate = useCallback(async (dataOrder) => {
    console.log(dataOrder);
    setIsLoading(true);
    try {
      await updateOrder(dataOrder._id, dataOrder);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  return {
    isLoading,
    dataOrders,
    getDataOrder,
    order,
    orderUpdate,
    // onUpda,
  };
};

export default useOrders;
