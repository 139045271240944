import SubscriptionServices from "../../../services/subscription/SubscriptionServices";

const useFormSubscribe = () => {
  const { createreference } = SubscriptionServices();

  const createSubscription = async (data) => {
      try {
        await createreference(data);
    } catch (error) {

    }
  };

  return { createSubscription };
};

export default useFormSubscribe;
