import { Button, Row } from "antd";
import styled from "styled-components";

const ContentSubscribeFooter = styled(Row)`
  font-family: "Baloo Tamma 2", system-ui;
  font-weight: 500;
  margin-bottom: 30px;

  span {
    font-size: 20px !important;
  }
  input {
    border-radius: 10px;
    background-color: white;
  min-height: 3rem !important;

  }
`;

const ButtonSubscribe = styled(Button)`
margin-top: 10px;
  min-height: 2rem !important;
  background-color: black;
  border-radius: 10px;
  width: 100%;
  background-color: black;
  min-height: 3rem !important;
  font-family: "Baloo Tamma 2", system-ui;
  color: white;
  span{
    font-size: 17px !important;
  }
  :hover {
    background: #dee2e6;
    color: black !important;
    border: 1px solid white !important;
  }
`;

export { ContentSubscribeFooter, ButtonSubscribe };
