import { Row } from "antd";
import styled from "styled-components";

const ContentViewOrder = styled(Row)`
  margin-bottom: 30px;
  padding: 20px;
  justify-content: center;
`;

const ContentImage = styled(Row)`
  img {
    max-width: 200px;
    max-height: 170px;
    min-height: 140px;
  }
`;

const ContentStatus = styled(Row)`
  background-color: ${(props) => props.color || "transparents"};
  border-color: ${(props) => props.color || "transparents"};
  border: ${(props) => `4px solid ${props.color}` || "transparents"};
  border-radius: 10px;
  text-align: center;
  justify-content: center;
  min-height: 30px;
`;

export { ContentViewOrder, ContentImage, ContentStatus };
