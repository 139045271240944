import React, { useContext, useState } from "react";
import SearchOrder from "../components/SearchOrder";
import { ContentViewOrder } from "../components/style";
import { Col, Row } from "antd";
import { TitleContentProduct } from "../../products/components/style/styleProducts";
import { ProductsContext } from "../../../components/products/context/ProductsContext";
import OrderDetails from "../components/orderDetails/OrderDetails";

const OrderPage = () => {
  const { ordersCrud } = useContext(ProductsContext);
  const { getDataOrder, order } = ordersCrud;
  const [dataSearchOrder, setDataSearchOrder] = useState();
  const onCaptureOrder = async (idOrder) => {
    await getDataOrder(idOrder);
    setDataSearchOrder(idOrder);
  };

  return (
    <>
      <Row justify={"center"} style={{ marginTop: "80px" }}>
        <Col xs={24} md={12}>
          <ContentViewOrder>
            <TitleContentProduct>Buscar estado de orden</TitleContentProduct>
            <SearchOrder onChange={onCaptureOrder} />

            <OrderDetails order={order} dataSearchOrder={dataSearchOrder}/>
          </ContentViewOrder>
        </Col>
      </Row>
    </>
  );
};

export default OrderPage;
