import { Row } from "antd";
import styled from "styled-components";

const ContentForm = styled(Row)`
  justify-content: center;
  align-content: center;
  margin-bottom: 40px;
  .field {
    min-height: 50px;
  }
  .custom {
    margin-top: 30px;
    margin-bottom: 30px;

    background-color: black;
    color: white;
    min-height: 50px;
  }
  .register {
    background: #f3ddd6;
    min-height: 50px;
  }
`;

const ContentLogo = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  img {
    display: flex;
    width: 150px;
  }
`;

export { ContentForm, ContentLogo };
