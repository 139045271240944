import React, { useContext } from "react";
import FilterFields from "./FilterFields";
import { ProductsContext } from "../products/context/ProductsContext";
import { CardFilterProducts } from "../products/pages/styled";
import { TitlePanelFilters } from "../products/components/style/styleProducts";
import { Row } from "antd";
import { SlidersOutlined } from "@ant-design/icons";

const ProductsFilterPanel = ({ initialCategorys }) => {
  const { searchCustom } = useContext(ProductsContext);
  const { productsFiltered } = searchCustom;

  const categoriesSet = new Set();
  const referencesSet = new Set();

  productsFiltered.forEach((product) => {
    product.category?.forEach((cat) => categoriesSet.add(JSON.stringify(cat)));
    if (product.reference) {
      referencesSet.add(JSON.stringify(product.reference));
    }
  });

  const categories = Array.from(categoriesSet).map((cat) => JSON.parse(cat));
  const references = Array.from(referencesSet).map((ref) => JSON.parse(ref));

  return (
    <>
      <Row>
        <TitlePanelFilters>
          <SlidersOutlined style={{ fontSize: "20px" , marginRight:'10px' }} />
          Filtros
        </TitlePanelFilters>
      </Row>

      <CardFilterProducts>
        <FilterFields
          categories={categories}
          references={references}
          initialCategorys={initialCategorys}
        />{" "}
      </CardFilterProducts>
    </>
  );
};

export default ProductsFilterPanel;
