import React, { useContext } from "react";
import ProductsFilterPanel from "../productsFilterPanel/ProductsFilterPanel";
import { Drawer, Menu } from "antd";
import { ProductsContext } from "../products/context/ProductsContext";

const DrawerFilter = ({ show, onChange }) => {
  const { searchCustom } = useContext(ProductsContext);
  const { productsFiltered } = searchCustom;
  return (
    <>
      <Drawer
        title="Filtros"
        placement="left"
        closable={true}
        onClose={onChange}
        visible={show}
      >
        <Menu mode="vertical" theme="light">
          <ProductsFilterPanel productsFiltered={productsFiltered} />
        </Menu>
      </Drawer>
    </>
  );
};

export default DrawerFilter;
