import React, { useContext, useState } from "react";
import { UnorderedListOutlined } from "@ant-design/icons";
import { Col, Menu } from "antd";
import { ProductsContext } from "../products/context/ProductsContext";

const FilterFields = ({
  categories = [],
  references = [],
  colors = [],
  initialCategorys,
}) => {
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const { searchCustom } = useContext(ProductsContext);
  const { getProductsFiltered } = searchCustom;

  console.log(initialCategorys);

  // Manejar los filtros seleccionados
  const [selectedFilters, setSelectedFilters] = useState({
    search: "",
    category: initialCategorys?.length > 0 ? initialCategorys : "",
    reference: "",
    color: "",
  });

  const handleFilterChange = (filterType, value) => {
    const newFilters = { ...selectedFilters };
    const newSelectedKeys = [...selectedKeys];
    const key = `${filterType.slice(0, 3)}-${value}`;

    // Si el filtro ya está seleccionado, se deselecciona
    if (newFilters[filterType] === value) {
      newFilters[filterType] = "";
      setSelectedKeys(newSelectedKeys.filter((k) => k !== key));
    } else {
      // Si no está seleccionado, se selecciona
      newFilters[filterType] = value;
      setSelectedKeys([...newSelectedKeys, key]);
    }

    setSelectedFilters(newFilters);
    getProductsFiltered(newFilters); // Actualizar productos filtrados
  };

  const getMenuItem = (type, items) =>
    getItem(
      type.charAt(0).toUpperCase() + type.slice(1),
      type,
      UnorderedListOutlined,
      items.map((item) =>
        getItem(item.name, `${type.slice(0, 3)}-${item._id || item}`)
      )
    );

  const getItem = (label, key, icon = null, children = null) => ({
    key,
    icon: icon ? React.createElement(icon) : null,
    label,
    children,
  });

  const filteredCategories = categories.filter(
    (category) =>
      !(category.name === "Deluxe" || category.name === "Nueva colección")
  );

  const menuItems = [
    getMenuItem("categorías", filteredCategories),
    getMenuItem("referencias", references),
    getMenuItem("colores", colors),
  ];

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  return (
    <Col xs={24} md={24}>
      {/* <CardFilter> */}
      <Menu
        mode="inline"
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        onOpenChange={onOpenChange}
        items={menuItems}
        onClick={({ key }) => {
          const [type, id] = key.split("-");
          const filterType =
            type === "cat"
              ? "category"
              : type === "ref"
              ? "reference"
              : "color";
          handleFilterChange(filterType, id);
        }}
      />
      {/* </CardFilter> */}
    </Col>
  );
};

export default FilterFields;
