import React from "react";
import PropType from "prop-types";
import { useContext } from "react";
import { ProductsContext } from "../products/context/ProductsContext";
import Products from "../products/components/Products";

const ProductsContainer = ({ searchMode = false, ouletMode }) => {
  const { productsCrud, searchCustom } = useContext(ProductsContext);
  const { productsFiltered } = searchCustom;
  const { products } = productsCrud;


  return (
    <Products
      products={searchMode ? productsFiltered : products}
      searchMode={searchMode}
    />
  );
};

export default ProductsContainer;

ProductsContainer.propType = {
  data: PropType.object,
};
