export const PAYMENT_STATUS = {
  PENDING_PAYMENT: {
    id: "PENDING_PAYMENT",
    label: "Pendiente de Pago",
    color: "#f8d7da",
  },
  PAYMENT_CONFIRMED: {
    id: "PAYMENT_CONFIRMED",
    label: "Pago Confirmado",
    color: "#d4edda",
  },
  PAYMENT_REJECTED: {
    id: "PAYMENT_REJECTED",
    label: "Pago Rechazado",
    color: "#f5c6cb",
  },
};

export const SHIPPING_STATUS = {
  PENDING_SEND: {
    id: "PENDING_SEND",
    label: "Pendiente de Envío",
    color: "#f8d7da",
  },
  SENT: { id: "SENT", label: "Envío Realizado", color: "#d4edda" },
};
