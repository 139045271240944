import axios from "axios";
import baseUrl from "../../utils/apis/BaseUrl";
import API from "../../utils/apis";

const codesServices = () => {
  const createCode = async (data) => {
    try {
      const codeResponse = await axios.post(
        `${baseUrl}${API.codes.root}`,
        data
      );
      return codeResponse.data;
    } catch (error) {
      console.error("Error creating code:", error);
      throw error;
    }
  };

  const updateCode = async (id, data) => {
    console.log(data);
    try {
      const codeResponse = await axios.put(
        `${baseUrl}${API.codes.root}${id}`,
        data
      );
      console.log(codeResponse);
      return codeResponse.data;
    } catch (error) {
      console.error("Error creating code:", error);
      throw error;
    }
  };

  const getAllCodes = async () => {
    try {
      const response = await axios.get(`${baseUrl}${API.codes.root}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching all codes:", error);
      throw error;
    }
  };

  const validateCode = async (code) => {
    const params = {
      name: code,
    };
    try {
      const response = await axios.post(
        `${baseUrl}${API.codes.root}${API.codes.validate}`,
        params
      );
      return response.data;
    } catch (error) {
      return {
        valid: false,
      };
    }
  };

  const getCode = async (idCode) => {
    try {
      const response = await axios.get(`${baseUrl}${API.codes.root}/${idCode}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching code with ID ${idCode}:`, error);
      throw error;
    }
  };

  const DeleteCode = async (idCode) => {
    try {
      const response = await axios.delete(
        `${baseUrl}${API.codes.root}${idCode}`
      );
      return response.data;
    } catch (error) {
      console.error(`Error fetching code with ID ${idCode}:`, error);
      throw error;
    }
  };

  return {
    createCode,
    getAllCodes,
    getCode,
    updateCode,
    DeleteCode,
    validateCode,
  };
};

export default codesServices;
