import baseUrl from "../../utils/apis/BaseUrl";
import API from "../../utils/apis";
import axios from "axios";

const SubscriptionServices = () => {
  const createreference = async (data) => {
    const references = await axios.post(
      `${baseUrl}${API.subscription.root}`,
      data
    );
    return {
      data: references.data,
    };
  };

  return {
    createreference,
  };
};

export default SubscriptionServices;
