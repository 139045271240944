import PropType from "prop-types";
import { SearchOutlined } from "@ant-design/icons";

import {
  ContentSearch,
  SearchButton,
  SearchInput,
} from "../../searchCustom/styled";
import { useState } from "react";

const SearchOrder = ({ modePage = true, onChange }) => {
  const [terms, setTerms] = useState();
  const onChangeSearch = (value) => {
    const term = value.target.value;
    setTerms(term);
    onChange(term);
  };

  return (
    <>
      <ContentSearch className="modalSearch">
        <SearchInput
          type="text"
          placeholder="Ingresa numero de orden..."
          value={terms}
          onChange={onChangeSearch}
          modePage={modePage}
        />
        <SearchButton>
          <SearchOutlined
            style={{
              fontSize: modePage ? "14px" : "18px",
              color: modePage ? "black" : "#f8f9f9",
            }}
          />
        </SearchButton>
      </ContentSearch>
    </>
  );
};

export default SearchOrder;

SearchOrder.propType = {
  onClose: PropType.func,
  onChange: PropType.func,
};
